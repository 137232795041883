"use client";
import { HoverCardArrow } from "@radix-ui/react-hover-card";
import { useParams } from "next/navigation";
import { useMemo } from "react";
import Blockies from "react-blockies";
import Marquee from "react-fast-marquee";
import useSWR from "swr";
import { useMediaQuery } from "usehooks-ts";
import { formatEther, zeroAddress } from "viem";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

import { useENS } from "../hooks/useENS";

const points = [
  { points: 25n, rank: 1 },
  { points: 18n, rank: 2 },
  { points: 15n, rank: 3 },
  { points: 12n, rank: 4 },
  { points: 10n, rank: 5 },
  { points: 8n, rank: 6 },
  { points: 6n, rank: 7 },
  { points: 4n, rank: 8 },
  { points: 2n, rank: 9 },
];

function SeasonLeaderboardRow({
  winner,
  pool,
}: {
  winner: {
    address: string;
    rank: string;
    points: number;
  };
  pool: bigint;
}) {
  const params = useParams<{
    s: string;
    player?: `0x${string}`;
  }>();
  const { data: ensData } = useENS({ address: winner.address });
  const isSmall = useMediaQuery("(max-width: 640px)");

  const { data: hasWarning } = useSWR(
    `/api/warning?address=${winner.address}`,
    (url: RequestInfo) =>
      fetch(url)
        .then((res) => res.json())
        .then((json) => {
          return Boolean(json.warnings.length);
        })
  );

  const ticketText = useMemo(() => {
    if (winner.rank === "1") return "and a free Devcon ticket";
    if (winner.rank === "2") return "and a 50% discount on a Devcon ticket";
    if (winner.rank === "3") return "and a 25% discount on a Devcon ticket";
    return "";
  }, [winner.rank]);

  console.warn("winner.rank", ticketText, typeof winner.rank);

  if (!params) return null;

  const { s, player: sharer } = params;

  const earnings = (points[parseInt(winner.rank) - 1].points * pool) / 100n;

  return (
    <>
      <HoverCard openDelay={200}>
        <HoverCardTrigger asChild>
          <div
            className={`grid grid-cols-7 items-center px-4 md:px-6 py-2 text-lg ${
              sharer?.toLowerCase() === winner.address &&
              winner.address !== zeroAddress
                ? "bg-yellow bg-opacity-30"
                : ""
            }`}
          >
            <Badge className="col-span-1 justify-self-start">
              &nbsp;&nbsp;{winner.rank}&nbsp;&nbsp;
            </Badge>

            <Avatar key={ensData?.avatar || winner.address}>
              <AvatarImage
                width={48}
                height={48}
                alt={winner.address}
                src={ensData?.avatar}
              />
              <AvatarFallback>
                <Blockies seed={winner.address} />
              </AvatarFallback>
            </Avatar>

            <p
              className={`overflow-hidden justify-self-start col-span-3`}
              data-tooltip={winner.address}
            >
              {(ensData?.displayName?.length || 0) > 25 ? (
                <Marquee>
                  <span className="mr-8">
                    {hasWarning ? "⚠️ " : ""}
                    {ensData?.displayName}
                  </span>
                </Marquee>
              ) : (
                [hasWarning ? "⚠️ " : "", ensData?.displayName]
              )}
            </p>

            <p className="col-span-2 text-right justify-self-end mr-0">
              {winner.points} <small>pts</small>
            </p>
          </div>
        </HoverCardTrigger>
        <HoverCardContent
          className="w-80 bg-white text-center"
          side={isSmall ? "top" : "right"}
        >
          <HoverCardArrow />
          Winning {formatEther(earnings)} ETH {ticketText}
          {hasWarning ? (
            <>
              <br />
              <span className="text-red">
                ⚠️ This player is under warning and faces a potential points
                deduction ⚠️
              </span>
            </>
          ) : null}
        </HoverCardContent>
      </HoverCard>
      <hr />
    </>
  );
}

export default SeasonLeaderboardRow;
