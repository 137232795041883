import dynamic from "next/dynamic";
import Image from "next/image";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

import ClientLeaderboards, {
  ClientLeaderboardTabs,
} from "./ClientLeaderboards";
import FarcasterFollowingLeaderboard from "./FarcasterFollowingLeaderboard";
import Leaderboard, { RankData } from "./Leaderboard";
import LeagueLeaderboard from "./LeagueLeaderboard";
import LensFollowingLeaderboard from "./LensFollowingLeaderboard";
import SeasonLeaderboard from "./SeasonLeaderboard";

const RotatingGlobeEmoji = dynamic(() => import("./RotatingGlobeEmoji"));

const LeaderboardSelector = ({
  seed,
  updatedAt,
  leaders,
}: {
  seed: string;
  updatedAt: bigint;
  leaders: RankData[];
}) => {
  return (
    <Tabs
      // key={fid}
      defaultValue={"global"}
      // onValueChange={(value) => setPreffered(value)}
      className="w-full m-auto lg:max-w-1/3 "
    >
      <TabsList className="h-12 w-full justify-center">
        <div className="flex flex-row m-auto text-lg overflow-scroll h-full">
          <TabsTrigger className="text-xl px-3 py-1 justify-start" value="cup">
            <span className="mr-1">🦄</span> Devcon Derby
          </TabsTrigger>
          {/* <TabsTrigger className="text-xl px-3 py-1 justify-start" value="cup">
            <span className="mr-1">🟡</span> Onchain Summer Series
          </TabsTrigger> */}
          <TabsTrigger
            className="text-xl px-3 py-1 justify-start"
            value="global"
          >
            <RotatingGlobeEmoji /> Global
          </TabsTrigger>
          <TabsTrigger
            className="text-xl px-3 py-1 flex flex-row items-center"
            value="following"
          >
            <i className="fc fc-farcaster text-[#8963D2] mr-1 mb-1"></i>{" "}
            <span>Following</span>
          </TabsTrigger>
          <TabsTrigger
            className="text-xl flex-shrink-0 flex flex-row items-center px-3 py-1"
            value="lens-following"
          >
            <Image
              alt="Lens logo"
              width={22}
              height={22}
              className="mr-1  mb-1"
              src="/lens.png"
            />
            <span>Following</span>
          </TabsTrigger>
          <ClientLeaderboardTabs />
        </div>
      </TabsList>
      <TabsContent value="cup">
        <SeasonLeaderboard />
      </TabsContent>
      <TabsContent value="global">
        <Leaderboard seed={seed} initialData={leaders} />
      </TabsContent>
      <TabsContent value="following">
        <FarcasterFollowingLeaderboard seed={seed} updatedAt={updatedAt} />
      </TabsContent>
      <TabsContent value="lens-following">
        <LensFollowingLeaderboard seed={seed} updatedAt={updatedAt} />
      </TabsContent>

      <ClientLeaderboards seed={seed} />
    </Tabs>
  );
};

export default LeaderboardSelector;
