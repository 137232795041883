"use client";

import useSWR from "swr";
import { parseEther } from "viem";
import { useReadContract } from "wagmi";
import { readContract } from "wagmi/actions";

import RaceResult from "../abi/RaceResult";
import { deepCamelCaseKeys } from "../utils/camelCase";
/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fSoyUxm92kl
 */
import EmptyRow from "./EmptyRow";
import SeasonLeaderboardRow from "./SeasonLeaderboardRow";

export interface RankData {
  address: string;
  rank: string;
  points: number;
}

export const fillBoard = (
  winners: (RankData | null)[] = [],
  desiredLength: number
) => {
  winners.length = desiredLength;
  winners.fill(
    null,
    winners.length - desiredLength + winners.filter(Boolean).length
  );
  return winners;
};

const fetcher = (args: any) =>
  fetch(args)
    .then((res) => res.json())
    .then((res) => deepCamelCaseKeys(res));

export default function SeasonLeaderboard() {
  const { data: _data } = useSWR(`/api/season-leaderboard`, fetcher);

  const data = _data as RankData[];

  // const { data: poolSize } = useReadContract({
  //   ...RaceResult,
  //   address: RaceResult.season2,
  //   functionName: "purse",
  // });

  const winners = fillBoard(data as RankData[], 9);

  return (
    <div className="w-full bg-white">
      <div>
        {winners?.map((winner, i) =>
          winner ? (
            <SeasonLeaderboardRow
              key={winner.rank}
              winner={winner}
              pool={parseEther("1")}
            />
          ) : (
            <EmptyRow key={i} />
          )
        )}
      </div>
    </div>
  );
}
