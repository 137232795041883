"use client";
import { useState } from "react";

import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerDescription,
  DrawerFooter,
  DrawerHeader,
  DrawerTitle,
  DrawerTrigger,
} from "@/components/ui/drawer";

import { RankData } from "./Leaderboard";
import LeaderboardSelector from "./LeaderboardSelector";

function getRandomInt(min: number, max: number) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

const LeaderboardDrawer = ({
  seed,
  updatedAt,
  leaders,
}: {
  seed: string;
  updatedAt: bigint;
  leaders: RankData[];
}) => {
  const [open, setOpen] = useState(false);

  return (
    <Drawer
      open={open}
      shouldScaleBackground={false}
      onOpenChange={(_open) => setOpen(_open)}
    >
      <DrawerTrigger onClick={() => setOpen((_open) => !_open)}>
        {!open && (
          <span className="absolute top-3 lg:top-1 right-3 z-10 text-xl cursor-pointer lg:hidden">
            🏆 <span className="global-shadow">Leaders</span>
          </span>
        )}
      </DrawerTrigger>
      <DrawerContent>
        <DrawerHeader className="text-left">
          <DrawerTitle>Leaderboard</DrawerTitle>
          <DrawerDescription>How do you stack up?</DrawerDescription>
        </DrawerHeader>
        <LeaderboardSelector
          seed={seed}
          updatedAt={updatedAt}
          leaders={leaders}
        />
        <DrawerFooter>
          <DrawerClose>Close</DrawerClose>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default LeaderboardDrawer;
