"use client";
import { profileId, useProfile } from "@lens-protocol/react-web";
import { HoverCardArrow } from "@radix-ui/react-hover-card";
import Image from "next/legacy/image";
import { useParams } from "next/navigation";
import Blockies from "react-blockies";
import Marquee from "react-fast-marquee";
import { useMediaQuery } from "usehooks-ts";
import { toHex, zeroAddress } from "viem";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { Badge } from "@/components/ui/badge";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/components/ui/hover-card";

import RaceResult from "../abi/RaceResult";
import { useENS } from "../hooks/useENS";
import { useMetadata } from "../hooks/useMetadata";
import { currentTrack } from "../utils/getTheme";

function LensLeaderboardRow({
  winner,
}: {
  winner: {
    address: string;
    time: number;
    tokenId: string;
    rank: number;
    streak?: number;
    fid: string;
    createdAt: string;
  };
}) {
  const { s, player: sharer } =
    useParams<{
      s: string;
      player?: `0x${string}`;
    }>() || {};

  const {
    data: profile,
    error,
    loading,
  } = useProfile({
    forProfileId: profileId(toHex(winner.fid)),
  });

  const metadata = useMetadata({
    createdAt: winner.createdAt,
    tokenId: winner.tokenId,
    track: s,
  });
  const isSmall = useMediaQuery("(max-width: 640px)");

  const streak =
    winner.streak && (!s || parseInt(s as string) === currentTrack())
      ? winner.streak + 1
      : null;

  if (!profile) return null;

  return (
    <>
      <HoverCard openDelay={200}>
        <HoverCardTrigger asChild>
          <div
            className={`grid grid-cols-7 items-center px-4 md:px-6 py-2 text-lg ${
              sharer?.toLowerCase() === winner.address &&
              winner.address !== zeroAddress
                ? "bg-yellow bg-opacity-30"
                : ""
            }`}
          >
            <Badge
              variant={winner.rank > 10 ? "outline" : "default"}
              className="col-span-1 justify-self-start"
            >
              &nbsp;&nbsp;{winner.rank}&nbsp;&nbsp;
            </Badge>

            <Avatar key={winner.address}>
              <AvatarImage
                width={48}
                height={48}
                alt={winner.address}
                src={
                  profile?.metadata?.picture?.__typename == "ImageSet"
                    ? profile.metadata.picture?.thumbnail?.uri
                    : undefined
                }
              />
              <AvatarFallback>
                <Blockies seed={winner.address} />
              </AvatarFallback>
            </Avatar>

            <p
              className={` overflow-hidden justify-self-start ${
                streak && winner.rank < 10 ? "col-span-3" : "col-span-4"
              }`}
              data-tooltip={winner.address}
            >
              {(profile?.handle?.localName?.length || 0) > 25 ? (
                <Marquee>
                  <span className="mr-8">{profile?.handle?.localName}</span>
                </Marquee>
              ) : (
                profile?.handle?.localName
              )}
            </p>
            {streak && winner.rank < 10 && (
              <Badge
                variant="secondary"
                className="col-span-1 mb-1 justify-self-start relative px-2"
              >
                {streak}
                🔥
              </Badge>
            )}
            <p className="col-span-1 text-right justify-self-end mr-0">
              {winner.time / 1000}
              <small>s</small>
            </p>
          </div>
        </HoverCardTrigger>
        {metadata?.data?.image_url?.endsWith(".svg") && (
          <HoverCardContent
            className="w-80 bg-white"
            side={isSmall ? "top" : "right"}
          >
            <HoverCardArrow />
            <a
              href={`https://opensea.io/assets/base/${RaceResult.metadataAddressForTrack(
                s ? parseInt(s as string) : 131
              ).toLowerCase()}/${winner.tokenId}`}
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Speedtracer Race Result image"
                width="514"
                height="320"
                src={metadata?.data?.image_url}
              />
            </a>
            {streak && winner.rank < 10 && (
              <p className="text-center mt-2">
                🔥 On the Leaderboard {streak} days in a row! 🔥
              </p>
            )}
          </HoverCardContent>
        )}
      </HoverCard>
      <hr />
    </>
  );
}

export default LensLeaderboardRow;
