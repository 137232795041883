"use client";
/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fSoyUxm92kl
 */
import {
  profileId,
  useLazyProfile,
  useLogin,
  useProfiles,
  useProfilesManaged,
  useSession,
} from "@lens-protocol/react-web";
import { ChevronRightCircleIcon } from "lucide-react";
import Blockies from "react-blockies";
import useSWR from "swr";
import { toHex } from "viem";
import { useAccount } from "wagmi";

import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";

import { deepCamelCaseKeys } from "../utils/camelCase";
import EmptyRow from "./EmptyRow";
import { fillBoard } from "./Leaderboard";
import LeaderboardRow from "./LeaderboardRow";
import LensLeaderboardRow from "./LensLeaderboardRow";

interface RankData {
  address: string;
  rank: number;
  time: number;
  tokenId: string;
  streak?: number;
  fid: string;
  createdAt: string;
}

const fetcher = (args: any) =>
  fetch(args)
    .then((res) => res.json())
    .then((res) => deepCamelCaseKeys(res));
export default function LensFollowingLeaderboard({
  seed,
  updatedAt,
}: {
  seed: string;
  updatedAt: bigint;
}) {
  const { address, isConnected } = useAccount();
  const { data: session } = useSession();
  const { execute: fetchProfile } = useLazyProfile();
  const { execute, loading, data: loginData } = useLogin();
  const { data: profiles } = useProfilesManaged({
    for: address as string,
    includeOwned: true,
  });

  const login = async (handle: string) => {
    const profileResult = await fetchProfile({ forHandle: handle });

    if (profileResult.isFailure()) {
      return;
    }

    const profile = profileResult.value;

    const loginResult = await execute({
      address: address as string,
      profileId: profile.id,
    });

    if (loginResult.isFailure()) {
      return;
    }
    // continue with successful login flow for example use
    // your routing library to redirect the user somewhere.
  };

  const { data: _data } = useSWR(
    session?.type === "WITH_PROFILE"
      ? `/api/lens-leaderboard?address=${address}&track=${seed}&fid=${parseInt(
          session?.profile?.id,
          16
        )}`
      : null,
    fetcher,
    { refreshInterval: 60_000 }
  );

  const data = _data as RankData[];

  const winners = fillBoard(data, 9) as RankData[];
  if (!session?.authenticated) {
    const rows = fillBoard([], 8 - (profiles?.length || 0));

    return (
      <div className="w-full h-full bg-white">
        <p className="text-center h-14  px-10">
          Login with a Lens profile to see scores from accounts you follow on
          Lens
        </p>
        <hr />
        {isConnected ? (
          profiles?.map((profile) =>
            profile?.handle && profile?.metadata ? (
              <div key={profile.id}>
                <div
                  onClick={() =>
                    profile?.handle?.fullHandle &&
                    login(profile.handle.fullHandle)
                  }
                  className="px-12 cursor-pointer h-14 flex flex-row items-center justify-between"
                >
                  <div className="text-xl flex flex-row items-center">
                    <Avatar key={profile.id} className="mr-2">
                      <AvatarImage
                        width={48}
                        height={48}
                        alt={profile.handle.suggestedFormatted.localName}
                        src={
                          profile?.metadata?.picture?.__typename == "ImageSet"
                            ? profile.metadata.picture?.thumbnail?.uri
                            : undefined
                        }
                      />
                      <AvatarFallback>
                        <Blockies seed={address} />
                      </AvatarFallback>
                    </Avatar>

                    {profile.handle.suggestedFormatted.full}
                  </div>

                  <ChevronRightCircleIcon />
                </div>
                <hr />
              </div>
            ) : null
          )
        ) : (
          <> </>
        )}
        {/* eslint-disable-next-line prettier/prettier */}
        {rows?.map((_, i) => <EmptyRow key={i} />)}
      </div>
    );
  }

  return (
    <div className="w-full h-full bg-white">
      {winners?.map((winner, i) =>
        winner ? (
          <LensLeaderboardRow key={winner.tokenId} winner={winner} />
        ) : (
          <EmptyRow key={i} />
        )
      )}

      {/* {isLoading && (
        <p className="absolute text-center text-gray-500">
          [Loading leaderboard...]
        </p>
      )}

      {!isLoading && !data?.length && (
        <p className="absolute text-center text-gray-500">[No winners]</p>
      )} */}
    </div>
  );
}
