import { useEffect, useState } from "react";

interface ENS {
  address?: `0x${string}`;
  name?: string;
  displayName: string;
  avatar?: string;
}

export const fetchEns = async (address: string): Promise<ENS> =>
  fetch(`https://api.ensideas.com/ens/resolve/${address}`).then((resp) =>
    resp.json()
  );

export const useENS = ({
  address,
  avatar,
}: {
  address?: `0x${string}` | string;
  avatar?: boolean;
}): { data?: ENS; error?: Error; isLoading: boolean } => {
  const [data, setData] = useState<ENS | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const cached = window?.localStorage?.getItem(`ens:user:${address}`);
    if (cached) {
      const parsedData = JSON.parse(cached);
      const stale = Date.now() - parsedData.date > 3600000;

      if (!stale) setData(parsedData.ens);
    }
  }, []);

  useEffect(() => {
    if (!address) {
      setData(undefined);
      setError(undefined);
      setIsLoading(false);
      return;
    }

    let isCancelled = false;
    setIsLoading(true);

    const fetchData = async () => {
      if (data?.avatar) return;

      try {
        const response = await fetchEns(address);
        if (avatar && !response.avatar) throw new Error("No avatar found");

        if (!isCancelled) {
          setData(response);
          setError(undefined);

          window.localStorage.setItem(
            `ens:user:${address}`,
            JSON.stringify({ ens: response, date: Date.now() })
          );
        }
      } catch (err) {
        if (!isCancelled) {
          setData(undefined);
          setError(err instanceof Error ? err : new Error("An error occurred"));
        }
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [address, avatar]);

  return { data, error, isLoading };
};
