/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fSoyUxm92kl
 */

import useSWR from "swr";
import { useAccount } from "wagmi";

import { deepCamelCaseKeys } from "../utils/camelCase";
import EmptyRow from "./EmptyRow";
import { fillBoard } from "./Leaderboard";
import LeaderboardRow from "./LeaderboardRow";

export interface RankData {
  address: string;
  rank: number;
  time: number;
  tokenId: string;
  createdAt: string;
}

const fetcher = (args: any) =>
  fetch(args)
    .then((res) => res.json())
    .then((res) => deepCamelCaseKeys(res));

export default function LeagueLeaderboard({
  seed,
  league,
}: {
  seed: string;
  league: string;
}) {
  const { address } = useAccount();
  const { data } = useSWR(
    `/api/league-leaderboard?address=${address}&track=${seed}&league=${league}`,
    fetcher,
    { refreshInterval: 60_000 }
  );

  const winners = fillBoard(data as RankData[], 9);

  return (
    <div className="w-full bg-white">
      <div>
        {winners?.map((winner, i) =>
          winner ? (
            <LeaderboardRow
              key={`${winner.tokenId}-${winner.time}`}
              winner={winner}
            />
          ) : (
            <EmptyRow key={i} />
          )
        )}
      </div>
      {/* {!isLoading && !data?.length && (
        <p className="absolute text-center text-gray-500">[No winners]</p>
      )}
      {isLoading && (
        <p className="absolute  text-center text-gray-500">
          [Loading leaderboard...]
        </p>
      )} */}
    </div>
  );
}
