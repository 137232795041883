import { useEffect, useState } from "react";

export const useLeagues = ({
  address,
}: {
  address: `0x${string}` | undefined;
}): { data?: any; error?: Error; isLoading: boolean } => {
  useEffect(() => {
    if (!address) return;
    const cached = window?.localStorage?.getItem(`leagues:user:${address}`);
    if (cached) {
      const parsedData = JSON.parse(cached);
      const stale = Date.now() - parsedData.date > 3600000;

      if (!stale) setData(parsedData.leagues);
    }
  }, [address]);

  const [data, setData] = useState<{ leagues?: string[] }>({});
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!address) return;
    let isCancelled = false;

    const fetchData = async () => {
      if (isCancelled) return; // Early return if the effect cleanup function was called

      setIsLoading(true);
      setError(undefined);

      try {
        const leagues = await fetch(`/api/leagues?address=${address}`).then(
          (res) => res.json()
        );

        setData(leagues);

        window.localStorage.setItem(
          `leagues:user:${address}`,
          JSON.stringify({ leagues, date: Date.now() })
        );
      } catch (err) {
        if (!isCancelled) {
          console.log("hook", err);
          setData({});
          setError(err instanceof Error ? err : new Error("An error occurred"));
        }
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    };

    address && fetchData();

    return () => {
      isCancelled = true;
    };
  }, [address]);

  return { data, error, isLoading };
};
