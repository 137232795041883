import { useEffect, useState } from "react";

import { currentTrack } from "../utils/getTheme";

export const useMetadata = ({
  createdAt,
  tokenId,
  track,
}: {
  createdAt: string;
  tokenId: string;
  track?: string;
}): { data?: any; error?: Error; isLoading: boolean } => {
  const [data, setData] = useState<any | undefined>(undefined);
  const [error, setError] = useState<Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  let _track = track;
  useEffect(() => {
    let isCancelled = false;

    if (!_track) {
      _track = currentTrack().toString();
    }

    const fetchData = async () => {
      const createdTs = parseInt(createdAt) * 1000;

      if (Date.now() - createdTs < 60 * 30) return;

      if (isCancelled) return; // Early return if the effect cleanup function was called

      setIsLoading(true);
      setError(undefined);

      try {
        const response = await fetch(
          `/api/metadata?tokenId=${tokenId}&track=${_track}`
        )
          .then((resp) => resp.json())
          .catch((e) => {
            console.error(e);
            setError(e instanceof Error ? e : new Error("An error occurred"));
            throw e; // Rethrow to skip setting data and potentially retry
          });

        if (!response.image_url) {
          setTimeout(() => {
            if (!isCancelled) {
              fetchData(); // Retry fetching after a delay
            }
          }, 120000); // 3000 ms delay before retrying
          return; // Skip setting data and isLoading state
        }

        setData({
          ...response,
          image_url:
            response?.previews?.image_opengraph_url || response.image_url,
        });
      } catch (err) {
        if (!isCancelled) {
          setData({
            image_url: "/apple-touch-icon.png", // Fallback image
            name: "",
            description: "",
          });
          setError(err instanceof Error ? err : new Error("An error occurred"));
        }
      } finally {
        if (!isCancelled) {
          setIsLoading(false);
        }
      }
    };

    fetchData();

    return () => {
      isCancelled = true;
    };
  }, [tokenId, _track]);

  return { data, error, isLoading };
};
