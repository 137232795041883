"use client";
import Image from "next/legacy/image";
import { useAccount } from "wagmi";

import { TabsContent, TabsTrigger } from "@/components/ui/tabs";

import { useLeagues } from "../hooks/useLeagues";
import LeagueLeaderboard from "./LeagueLeaderboard";

const LEAGUES = [
  {
    collection_id: "8a053333e45288f76bb5d1d9173bb50b",
    name: "Based Ghouls",
    description: null,
    image_url:
      "https://lh3.googleusercontent.com/rjqeiHMOX285jirPJIYX-68AoeYAwFz_VPm-V7Ib5tLWx0yw6D3pJeIsbJEe_pwANrMUuGDeBAprddHKfi27MfJdMaGii2WLFYs",
  },
  {
    collection_id: "5ef88640944fae4404476c79529b29b0",
    name: "Based OnChain Dinos",
    description:
      "Based OnChain Dinos is a collection of 2,000 NFTs, each randomly generated and distinctly determined at the time of minting. These Dinos are stored entirely onchain, including images and metadata.\n\nThank you to Tiny Dinos for the amazing cc0 art!",
    image_url:
      "https://lh3.googleusercontent.com/YbIMRfDYMLB6KBZVF2A63_dliMD1mJLjPrBg5aQCxv-8qButYOaf8znNpMTuyYWxvEu9HfWpT0I-1Jsk4ZqhXUi_3uC1jXQLGh0",
  },
];

export const ClientLeaderboardTabs = () => {
  const { address } = useAccount();
  const { data, error } = useLeagues({ address });

  return (
    <>
      {data?.leagues?.map((league: any) => (
        <TabsTrigger
          key={league.collection_id}
          className="flex-shrink-0 flex flex-row items-center text-xl px-3 py-1"
          value={league.collection_id}
        >
          <Image
            className="mb-1 mr-1"
            alt="League icon"
            width={22}
            height="22"
            src={league.image_url}
          />{" "}
          <span className="block ml-1">{league.name}</span>
        </TabsTrigger>
      ))}
    </>
  );
};

const ClientLeaderboards = ({ seed }: { seed: string }) => {
  const { address } = useAccount();
  const { data, error } = useLeagues({ address });

  return (
    <>
      {data?.leagues?.map((league: any) => (
        <TabsContent key={league.collection_id} value={league.collection_id}>
          <LeagueLeaderboard seed={seed} league={league.collection_id} />
        </TabsContent>
      ))}
    </>
  );
};

export default ClientLeaderboards;
