"use client";

function EmptyRow() {
  return (
    <>
      <div className="grid grid-cols-7 items-center py-2 text-lg h-14"></div>
      <hr />
    </>
  );
}

export default EmptyRow;
