"use client";
/**
 * v0 by Vercel.
 * @see https://v0.dev/t/fSoyUxm92kl
 */

import useSWR from "swr";
import { useAccount } from "wagmi";

import { deepCamelCaseKeys } from "../utils/camelCase";
import EmptyRow from "./EmptyRow";
import { fillBoard, RankData } from "./Leaderboard";
import LeaderboardRow from "./LeaderboardRow";

const fetcher = (args: any) =>
  fetch(args)
    .then((res) => res.json())
    .then((res) => deepCamelCaseKeys(res));
export default function FarcasterFollowingLeaderboard({
  seed,
  updatedAt,
}: {
  seed: string;
  updatedAt: bigint;
}) {
  const { address } = useAccount();

  const { data: _data } = useSWR(
    `/api/farcaster-leaderboard?address=${address}&track=${seed}`,
    fetcher,
    { refreshInterval: 60_000 }
  );

  const data = _data as RankData[];
  const winners = fillBoard(data, 9);

  return (
    <div className="w-full h-full bg-white">
      {winners?.map((winner, i) =>
        winner ? (
          <LeaderboardRow
            key={`${winner.tokenId}-${winner.time}`}
            winner={winner}
          />
        ) : (
          <EmptyRow key={i} />
        )
      )}

      {/* {isLoading && (
        <p className="absolute text-center text-gray-500">
          [Loading leaderboard...]
        </p>
      )}

      {!isLoading && !data?.length && (
        <p className="absolute text-center text-gray-500">[No winners]</p>
      )} */}
    </div>
  );
}
